import * as React from 'react'
import styled from 'styled-components'
import { Signature as SignatureInterface, SignatureType } from '../../types'
import SignatureContainer from './SignatureContainer'
import SignatureCanvas from './SignatureCanvas'

interface IProps {
  signature: SignatureInterface
}

const SignatureImage = styled.img`
	width: 100%;
	height: 100%;
`

const SignatureName = styled.div`
	font-weight: bold;
`

const SignatureTimestamp = styled.div`
	font-size: 12px;
`

const SignatureIpAddress = styled.div`
	font-size: 12px;
`

const Signature = (props: IProps) => {
  const { signature } = props
  return (
    <SignatureContainer>
      <SignatureCanvas>
        {signature.type === SignatureType.TYPED && <></>}
        {[SignatureType.DRAW, SignatureType.UPLOAD].includes(signature.type) && <SignatureImage src={signature.signature_url} />}
      </SignatureCanvas>

      <SignatureName>
        {signature.full_name}
      </SignatureName>

      {signature.signed_at && <SignatureTimestamp>
        {signature.signed_at}
      </SignatureTimestamp>}

      {signature.ip_address && <SignatureIpAddress>
        {`IP ${signature.ip_address}`}
      </SignatureIpAddress>}
    </SignatureContainer>
  )
}

export default Signature