import * as React from 'react'
import styled from 'styled-components'
import { ImageBlock as ImageBlockType } from '../../../types'

const Container = styled.div`
	width: 100%;
	break-inside: avoid;
`


type IProps = {
	block: ImageBlockType
}

const ImageBlock = (props: IProps) => {
	const { block } = props

	if (!block.image_url) return null

	return (
		<Container>
			<img src={block.image_url} />
		</Container>
	)
}

export default ImageBlock