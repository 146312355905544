import * as React from 'react'
import { LinkInputBlock } from '../../../../types'

interface IProps {
	block: LinkInputBlock
}

const LinkInput = (props: IProps) => {
	const { block } = props

	const validateUrl = (linkValue: string) => {
		let url;

		try {
			url = new URL(linkValue);
		} catch (_) {
		}

		return url.protocol === "http:" || url.protocol === "https:";
	}

	const onBlur = (e) => {
		const linkValue = e.target.value

		if (validateUrl(linkValue)) {
			// Valid
		} else {
			// Add errors here
		}
	}

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<input
					type='text'
					name={block.name}
					required={block.required}
					onBlur={onBlur}
					placeholder={block.placeholder || 'https://www.mywebsite.com'}
				/>
			</div>
		</div>
	)
}

export default LinkInput 