import { Blob } from '@rails/activestorage'
import * as React from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ActiveStorageController } from '../../../../controllers'
import { Style } from '../../../../styles'
import { FileUploadInputBlock } from '../../../../types'
import Icon from '../../../Icons/Icon'

const ButtonContainer = styled.div`
	width: fit-content;
`

const Button = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid #C4CDD5;
	padding: ${Style.spacing.x1} ${Style.spacing.x2};
	transition: all 250ms ease-in-out;
	font-size: 14px;
	color: #212B36;
	border-radius: 3px;
	pointer-events: auto !important;

	&:hover {
		background: #FAFBFC;
	}
`

const FileContainer = styled.div`
	width: fit-content;
	padding: ${Style.spacing.x1};
	border: 1px solid ${Style.color.border};
	border-radius: 4px;
	
	i {
		margin-right: 4px;
	}
`

interface IProps {
	block: FileUploadInputBlock
}

const FileUploadInput = (props: IProps) => {
	const { block } = props
	const { t } = useTranslation()
	const [uploading, setUploading] = React.useState<boolean>(false)
	const [fileBlob, setFileBlob] = React.useState<Blob | null>(null)
	const [fileUrl, setFileUrl] = React.useState<string | null>(null)

	const onDrop = (acceptedFiles: any[], fileRejections: FileRejection[], event: DropEvent) => {
		try {
			setUploading(true)
			ActiveStorageController.uploadPublic(acceptedFiles[0], async (error, blob) => {
				if (error) { console.error(error) }
				if (blob) {
					setFileBlob(blob)
					const { url } = await ActiveStorageController.getBlobUrl(blob)
					setUploading(false)
					setFileUrl(url)
				}
			})
		} catch (ex) {
			console.error(ex)
			setUploading(false)
		}
	}

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: onDrop,
		multiple: false,
	})

	const onRemoveFileClick = () => {
		setFileBlob(null)
		setFileUrl(null)
	}

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				{uploading && <div className='form-item' style={{ color: Style.color.brandWarning }}>
					{t('FileUploadInput::Uploading file...')}
				</div>}
				{fileBlob && <div className='form-item'>
					<FileContainer>
						<Icon icon='files' />
						{fileBlob.filename}
					</FileContainer>
				</div>}

				<div className='form-item'>
					<ButtonContainer {...getRootProps()}>
						<Button href='javascript://' onClick={() => { }}>
							{fileBlob ? t('FileUploadInput::Change file') : t('FileUploadInput::Add file')}
						</Button>
						<input {...getInputProps()} />
					</ButtonContainer>
				</div>

				<div className='form-item'>
					<ButtonContainer>
						{fileBlob && <Button href='javascript://' onClick={onRemoveFileClick}>
							{t('FileUploadInput::Remove file')}
						</Button>}
					</ButtonContainer>
				</div>

				<input
					type='hidden'
					name={block.name}
					required={block.required}
					value={fileUrl}
				/>
			</div>
		</div>
	)
}

export default FileUploadInput