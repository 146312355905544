import * as React from 'react'
import { ContentBlock, ContentBlockType, ContentBlockVariables, ItemsBlock as ItemsBlockType, NumberFormat, Signature, QuestionAndAnswerBlock as QuestionAndAnswerBlockType } from '../../types'
import BlockContent from './BlockContent'
import ImageBlock from './RenderBlocks/ImageBlock'
import IntroBlock from './RenderBlocks/IntroBlock'
import SignBlock from './RenderBlocks/SignBlock'
import ItemsBlock from './RenderBlocks/ItemsBlock'
import SummaryBlock from './RenderBlocks/SummaryBlock'
import TextBlock from './RenderBlocks/TextBlock'
import SubmitBlock from './RenderBlocks/SubmitBlock'
import InputBlock from './RenderBlocks/InputBlock'
import PDFBlock from './RenderBlocks/PDFBlock'
import QuestionAndAnswerBlock from './RenderBlocks/QuestionAndAnswerBlock'

export enum BlockRenderMode {
	EDITOR = 'editor',
	RENDER = 'render',
}

interface IProps {
	renderMode?: BlockRenderMode
	blocks: ContentBlock[]
	variables: ContentBlockVariables
	currency: string
	numberFormat: NumberFormat
	locked?: boolean
	signKey?: string
	signatures?: Signature[]
	onSignClick?: () => void
	onItemsBlockItemSelectionChange?: (itemsBlock: ItemsBlockType, itemIndex: number) => void
	onAnswerChange?: (block: QuestionAndAnswerBlockType) => void
}

const BlocksRenderer = (props: IProps) => {
	const {
		renderMode,
		blocks,
		variables,
		currency,
		numberFormat,
		signKey,
		signatures,
		onSignClick,
		onItemsBlockItemSelectionChange,
	} = props
	const itemBlocks: ItemsBlockType[] = blocks.filter(block => block.type === ContentBlockType.ITEMS) as ItemsBlockType[]

	let component = null
	let className = ''

	return <>
		{blocks.map(block => {
			switch (block.type) {
				case ContentBlockType.TEXT:
					component = <TextBlock block={block} variables={variables} />
					className = 'block-text'
					break
				case ContentBlockType.IMAGE:
					component = <ImageBlock block={block} />
					className = 'block-image'
					break
				case ContentBlockType.PDF:
					component = <PDFBlock block={block} />
					className = 'block-pdf'
					break
				case ContentBlockType.SIGN:
					component = <SignBlock
						renderMode={renderMode}
						block={block}
						signKey={signKey}
						signatures={signatures || []}
						onSignClick={onSignClick}
					/>
					className = 'block-sign'
					break
				case ContentBlockType.INTRO:
					component = <IntroBlock block={block} variables={variables} />
					className = 'block-intro'
					break
				case ContentBlockType.ITEMS:
					component = <ItemsBlock
						renderMode={renderMode}
						currency={currency}
						numberFormat={numberFormat}
						block={block}
						onItemSelectionChange={onItemsBlockItemSelectionChange}
					/>
					className = 'block-items'
					break
				case ContentBlockType.SUMMARY:
					component = <SummaryBlock
						currency={currency}
						numberFormat={numberFormat}
						items={itemBlocks}
						block={block}
					/>
					className = 'block-summary'
					break
				case ContentBlockType.ADDRESS_INPUT:
				case ContentBlockType.COUNTRIES_INPUT:
				case ContentBlockType.DATE_INPUT:
				case ContentBlockType.DROPDOWN_INPUT:
				case ContentBlockType.EMAIL_INPUT:
				case ContentBlockType.FILE_UPLOAD_INPUT:
				case ContentBlockType.LINK_INPUT:
				case ContentBlockType.LONG_TEXT_INPUT:
				case ContentBlockType.NUMBER_INPUT:
				case ContentBlockType.PHONE_NUMBER_INPUT:
				case ContentBlockType.TEXT_INPUT:
				case ContentBlockType.SLIDER_INPUT:
					component = <InputBlock
						block={block}
					/>
					className = 'block-input'
					break
				case ContentBlockType.QUESTION_AND_ANSWER:
					component = <QuestionAndAnswerBlock
						block={block}
						onChange={props.onAnswerChange}
					/>
					className = 'block-question-and-answer'
					break
				case ContentBlockType.SUBMIT:
					component = <SubmitBlock
						block={block}
					/>
					className = 'block-submit'
					break
			}

			if (!component) return null

			return (
				<BlockContent key={block.id} className={className}>
					{component}
				</BlockContent>
			)
		})}
	</>
}

export default BlocksRenderer