import Fuse from 'fuse.js'
import { IPositionable, ItemBlock, ItemsBlock, ItemsSelectionRequirementType, ItemsSelectionType, SearchableContentBlock, Signature } from "../types"

interface Summary {
	subtotal: number
	tax: number
	// discount: number
	total: number
}



export default class ContentBlockHelper {
	static POSITION_STEP_INDEX = 16384

	static getCreatePosition(resources: IPositionable[]): number {
		if (resources.length === 0) return this.POSITION_STEP_INDEX

		return resources[0].position / 2
	}

	static getCreatePositionFromIndex<T extends IPositionable>(items: T[], index: number): number {
		if (items.length === 0) return this.POSITION_STEP_INDEX

		const indexContentBlock = items[index]
		const destinationContentBlock = items[index + 1]

		if (destinationContentBlock) { // Moved in between
			return (indexContentBlock.position + destinationContentBlock.position) / 2
		} else { // Moved to last position
			return indexContentBlock.position + this.POSITION_STEP_INDEX
		}
	}

	static getSignedSignatures(signatures: Signature[]): Signature[] {
		if (!signatures) return []

		return signatures.filter(signature => Boolean(signature.signed_at))
	}

	static getUnsignedSignatures(signatures: Signature[]): Signature[] {
		if (!signatures) return []

		return signatures.filter(signature => !Boolean(signature.signed_at))
	}

	static getUnsignedUserSignature(signatures: Signature[], signKey?: string): Signature | null {
		if (!signatures || !signKey) return null

		return signatures.find(signature => signature.key === signKey && !Boolean(signature.signed_at))
	}

	static getItemSummary(item: ItemBlock): Summary {
		let subtotal = 0
		let tax = 0

		if (item.quantity && item.amount) {
			subtotal = Number(item.quantity) * Number(item.amount)
		}

		if (item.tax_rate) tax += subtotal * Number(item.tax_rate)

		return {
			subtotal: subtotal,
			tax: tax,
			// discount: 0,
			total: subtotal + tax
		}
	}

	static getItemsBlockSummary(itemsBlock: ItemsBlock): Summary {
		const selectedItems = itemsBlock.contact_selection_enabled ? itemsBlock.items.filter(item => item.selected) : itemsBlock.items

		const subtotal = selectedItems.reduce((subtotal, item) => subtotal + this.getItemSummary(item).subtotal, 0)
		const tax = selectedItems.reduce((tax, item) => tax + this.getItemSummary(item).tax, 0)
		const total = subtotal + tax

		return {
			subtotal: subtotal,
			tax: tax,
			// discount: 0,
			total: total
		}
	}

	static getSummary(items: ItemsBlock[]): Summary {
		return {
			subtotal: items.reduce((subtotal, item) => subtotal + this.getItemsBlockSummary(item).subtotal, 0),
			tax: items.reduce((tax, item) => tax + this.getItemsBlockSummary(item).tax, 0),
			// discount: items.reduce((discount, item) => discount + this.getItemsBlockSummary(item).discount, 0),
			total: items.reduce((total, item) => total + this.getItemsBlockSummary(item).total, 0),
		}
	}

	static getSelectionChangeItems(itemsBlock: ItemsBlock, itemIndex: number): ItemBlock[] {
		const item = itemsBlock.items[itemIndex]
		const newItemBlockSelected = !item.selected

		if (itemsBlock.selection_type === ItemsSelectionType.SINGLE) {
			if (newItemBlockSelected) {
				let newItems = itemsBlock.items.map(item => ({ ...item, selected: false }))

				newItems[itemIndex] = { ...item, selected: newItemBlockSelected }

				return newItems
			} else {
				if (itemsBlock.selection_requirement_type === ItemsSelectionRequirementType.REQUIRED) {
					return [...itemsBlock.items]
				} else if (itemsBlock.selection_requirement_type === ItemsSelectionRequirementType.OPTIONAL) {
					let newItems = [...itemsBlock.items]

					newItems[itemIndex] = { ...item, selected: newItemBlockSelected }

					return newItems
				}
			}
		} else if (itemsBlock.selection_type === ItemsSelectionType.MULTI) {
			if (newItemBlockSelected) {
				let newItems = [...itemsBlock.items]

				newItems[itemIndex] = { ...item, selected: newItemBlockSelected }

				return newItems
			} else {
				if (itemsBlock.selection_requirement_type === ItemsSelectionRequirementType.REQUIRED) {
					const selectedItemsCount = itemsBlock.items.filter(item => item.selected).length

					if (selectedItemsCount === 1) {
						return [...itemsBlock.items]
					} else {
						let newItems = [...itemsBlock.items]

						newItems[itemIndex] = { ...item, selected: newItemBlockSelected }

						return newItems
					}
				} else if (itemsBlock.selection_requirement_type === ItemsSelectionRequirementType.OPTIONAL) {
					let newItems = [...itemsBlock.items]

					newItems[itemIndex] = { ...item, selected: newItemBlockSelected }

					return newItems
				}
			}
		}
	}

	static getContentBlocksFromSearch(searchValue: string, searchableContentBlocks: SearchableContentBlock[]): SearchableContentBlock[] {
		const fuse = new Fuse(searchableContentBlocks, {
			shouldSort: true,
			threshold: 0.5,
			location: 0,
			distance: 100,
			minMatchCharLength: 1,
			keys: [
				'name',
				'notes',
				'amount',
				'contact.name',
				'project.name',
				'assignee.email',
				'assignee.first_name',
				'assignee.last_name'
			]
		})

		if (searchValue.length > 0) {
			return fuse.search(searchValue).map(result => result.item)
		}

		return searchableContentBlocks
	}
}