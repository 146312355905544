import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ContentBlockHelper from '../../../helpers/ContentBlockHelper'
import { Style } from '../../../styles'
import { ItemsBlock, SummaryBlock as SummaryBlockType } from '../../../types'
import NumberFormatter from '../../../utilities/NumberFormatter'
import { OverviewItem, OverviewItemLabel, OverviewItemValue } from '../InputBlocks/SummaryBlock'

const Container = styled.div`
	background: white;
	break-inside: avoid;
`

const OverviewTitle = styled.div`
	font-weight: 700;
	font-size: 18.75px;
	margin-bottom: ${Style.spacing.x1};
`

export const Overview = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
`

type IProps = {
	currency: string
	numberFormat: string
	items: ItemsBlock[]
	block: SummaryBlockType
}

const SummaryBlock = (props: IProps) => {
	const { block, items, currency, numberFormat } = props
	const { t } = useTranslation()

	const summary = ContentBlockHelper.getSummary(items)

	return (
		<Container>
			<OverviewTitle>
				{block.title}
			</OverviewTitle>
			<Overview>
				<OverviewItem>
					<OverviewItemLabel>
						{t('SummaryBlock::Subtotal')}
					</OverviewItemLabel>
					<OverviewItemValue>
						{NumberFormatter.formatCurrency(currency, numberFormat, summary.subtotal)}
					</OverviewItemValue>
				</OverviewItem>
				<OverviewItem>
					<OverviewItemLabel>
						{t('SummaryBlock::Tax')}
					</OverviewItemLabel>
					<OverviewItemValue>
						{NumberFormatter.formatCurrency(currency, numberFormat, summary.tax)}
					</OverviewItemValue>
				</OverviewItem>
				<OverviewItem>
					<OverviewItemLabel>
						{t('SummaryBlock::Total')}
					</OverviewItemLabel>
					<OverviewItemValue className='bold'>
						{NumberFormatter.formatCurrency(currency, numberFormat, summary.total)}
					</OverviewItemValue>
				</OverviewItem>
			</Overview>
		</Container>
	)
}

export default SummaryBlock