import i18n from "../translations";
import { VariableOption } from "../types";

export default class EditorHelper {
  static isUnquotedVariable(variable: string): boolean {
    return ["link", "link_preview"].includes(variable);
  }

  static getLedgerItemVariableOptions(variables: {
    [key: string]: any;
  }): VariableOption[] {
    const parentKey = "ledger_item";

    return [
      {
        name: parentKey,
        label: i18n.t(`EditorVariables::${parentKey}::EntityName`),
        value: "ledger_item",
        options: Object.entries(variables).map((valueResult) => {
          const [valueKey, valuePlaceholder] = valueResult;

          // console.log(i18n.t(`EditorVariables::${parentKey}::${valueKey}`))

          return {
            name: valueKey,
            label: i18n.t(`EditorVariables::${parentKey}::${valueKey}`),
            value: this.isUnquotedVariable(valueKey)
              ? `{{{${valueKey}}}}`
              : `{{${valueKey}}}`,
            placeholder: valuePlaceholder,
          };
        }),
      },
    ];
  }

  static getVariableOptions(variables: {
    [key: string]: any;
  }): VariableOption[] {
    if (Object.keys(variables).length === 0) return [];

    return Object.entries(variables).map((result) => {
      const [variableKey, values] = result;

      let variableOptions: VariableOption[] = [];

      if (values) {
        variableOptions = Object.entries(values).map((valueResult) => {
          const [valueKey, valuePlaceholder] = valueResult;

          // console.log(i18n.t(`EditorVariables::${variableKey}::${valueKey}`))

          return {
            name: valueKey,
            label: i18n.t(`EditorVariables::${variableKey}::${valueKey}`),
            value: this.isUnquotedVariable(valueKey)
              ? `{{{${variableKey}.${valueKey}}}}`
              : `{{${variableKey}.${valueKey}}}`,
            placeholder: valuePlaceholder,
          };
        });
      }

      // console.log(i18n.t(`EditorVariables::${variableKey}::EntityName`))

      return {
        name: variableKey,
        label: i18n.t(`EditorVariables::${variableKey}::EntityName`),
        value: variableKey,
        options: variableOptions,
      };
    });
  }
}
