import styled from 'styled-components'
import { Style } from '../../styles'

const SignatureCanvas = styled.div`
	position: relative;
	background: rgba(53, 48, 150, 0.08);
	border: 1px solid ${Style.color.border};
	border-radius: 3px;
	padding: ${Style.spacing.x1};
	height: 70px;
	min-width: 172px;
	width: fit-content;
	display: flex;
	page-break-inside: avoid;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	margin-bottom: ${Style.spacing.x1};

	&:before {
		position: absolute;
    right: 10px;
    bottom: 25px;
    left: 10px;
    content: "";
    border-top-width: 1px;
    border-top-style: dashed;
    border-top-color: inherit;
	}
`

export default SignatureCanvas