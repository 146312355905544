import { Moment } from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownInputBlock } from '../../../../types'
import PowerSelect from '../../../Form/PowerSelect'

interface IProps {
	block: DropdownInputBlock
}

const DropdownInput = (props: IProps) => {
	const { block } = props
	const { t } = useTranslation()
	const [selectedOptions, setSelectedOptions] = React.useState<string[]>([])

	const options = block.options.map(option => ({ label: option, value: option }))
	const selectedDropdownOptions = options.filter(option => selectedOptions.includes(option.value))

	const onChange = (option) => {
		if (block.allow_multiple_selection) {
			const options: { label: string, value: string }[] = option || []
			setSelectedOptions(options.map(o => o.value))
		} else {
			if (option) {
				setSelectedOptions([option.value])
			} else {
				setSelectedOptions([])
			}
		}
	}

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<PowerSelect
					options={options}
					value={selectedDropdownOptions}
					onChange={onChange}
					isClearable={true}
					placeholder={t('DropdownInput::Select your answer...')}
					// @ts-ignore
					isMulti={block.allow_multiple_selection}
				/>
				<input type='hidden' name={block.name} required={block.required} value={selectedOptions.join(',')} />
			</div>
		</div>
	)
}

export default DropdownInput