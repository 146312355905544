import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ContentBlockHelper from '../../../helpers/ContentBlockHelper'
import { Style } from '../../../styles'
import { ItemsBlock, SummaryBlock as SummaryBlockType } from '../../../types'
import NumberFormatter from '../../../utilities/NumberFormatter'
import { EditBlockProps } from '../Block'

const Container = styled.div`
	background: white;
	padding: 20px;
	padding-top: 16px;
	border-radius: ${Style.variables.baseBorderRadius};
`

const OverviewTitle = styled.input`
	font-weight: 700;
	font-size: 18.75px;
	border: none !important;

	&:active, &:focus {
		box-shadow: none !important;
	}
`

export const Overview = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
`

export const OverviewItem = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	&:not(:last-child) {
		border-bottom: 1px solid ${Style.color.border};
	}
`

export const OverviewItemLabel = styled.div`
	padding: ${Style.spacing.x1_5};
	font-weight: bold;
`

export const OverviewItemValue = styled.div`
	padding: ${Style.spacing.x1_5};

	&.bold {
		font-weight: bold;
	}
`

type IProps = {
	currency: string
	numberFormat: string
	items: ItemsBlock[]
} & EditBlockProps<SummaryBlockType>

const SummaryBlock = (props: IProps) => {
	const { block, items, currency, numberFormat } = props
	const { t } = useTranslation()
	const [title, setTitle] = React.useState(block.title)

	const summary = ContentBlockHelper.getSummary(items)

	const onTitleChange = (e) => {
		const newTitle = e.target.value

		setTitle(newTitle)

		props.onChange({ ...block, title: newTitle })
	}

	return (
		<Container>
			<OverviewTitle value={title} onChange={onTitleChange} />
			<Overview>
				<OverviewItem>
					<OverviewItemLabel>
						{t('SummaryBlock::Subtotal')}
					</OverviewItemLabel>
					<OverviewItemValue>
						{NumberFormatter.formatCurrency(currency, numberFormat, summary.subtotal)}
					</OverviewItemValue>
				</OverviewItem>
				<OverviewItem>
					<OverviewItemLabel>
						{t('SummaryBlock::Tax')}
					</OverviewItemLabel>
					<OverviewItemValue>
						{NumberFormatter.formatCurrency(currency, numberFormat, summary.tax)}
					</OverviewItemValue>
				</OverviewItem>
				<OverviewItem>
					<OverviewItemLabel>
						{t('SummaryBlock::Total')}
					</OverviewItemLabel>
					<OverviewItemValue className='bold'>
						{NumberFormatter.formatCurrency(currency, numberFormat, summary.total)}
					</OverviewItemValue>
				</OverviewItem>
			</Overview>
		</Container>
	)
}

export default SummaryBlock