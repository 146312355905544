import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../../styles'
import { SliderInputBlock } from '../../../../types'

const SliderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const SliderInputContainer = styled.div`
	width: 100%;

	input {
		height: 8px !important;
		padding: 0px !important;
	}
`


const SliderValue = styled.div`
	font-weight: bold;
	margin-left: ${Style.spacing.x1};
	min-width: 50px;
`

interface IProps {
	block: SliderInputBlock
}

const SliderInput = (props: IProps) => {
	const { block } = props

	const [value, setValue] = React.useState(0)

	const onChange = (e) => {
		const newValue = e.currentTarget.value

		setValue(newValue)
	}

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<SliderContainer>
					<SliderInputContainer>
						<input
							name={block.name}
							type='range'
							required={block.required}
							min={block.minimum_limit}
							max={block.maximum_limit}
							onChange={onChange}
							value={value}
						/>
					</SliderInputContainer>

					<SliderValue>
						{value}
					</SliderValue>
				</SliderContainer>
			</div>
		</div>
	)
}

export default SliderInput