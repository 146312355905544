import * as React from 'react'
import styled, { css } from 'styled-components'
import ContentBlockHelper from '../../../helpers/ContentBlockHelper'
import { Style } from '../../../styles'
import { Signature as SignatureType, SignBlock as SignBlockType } from '../../../types'
import { BlockRenderMode } from '../BlocksRenderer'
import Icon from '../../Icons/Icon'
import SignatureCanvas from '../../Signature/SignatureCanvas'
import SignatureContainer from '../../Signature/SignatureContainer'
import Signature from '../../Signature/Signature'

const Container = styled.div`
	break-inside: avoid;
`

const Title = styled.h2`
	font-size: 22px;
	font-weight: 700;
	margin-bottom: ${Style.spacing.x1};
`

const Description = styled.p`
	margin-bottom: ${Style.spacing.x1_5};
`

export const SignAction = styled.div`
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 6px ${Style.spacing.x2};
	background-color: ${Style.color.brandSuccess};
	color: white;
	font-weight: bold;
	border-radius: 5px;
	z-index: 1;


	svg {
		width: 16px;
		height: 16px;
		margin-right: ${Style.spacing.x1};
	}

	${props => props.onClick && css`
		cursor: pointer;
	`}
`

const Signatures = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	${SignatureContainer} {
		margin: 0 8px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
`

type IProps = {
	block: SignBlockType
	signKey?: string
	signatures: SignatureType[]
	renderMode?: BlockRenderMode
	onSignClick?: () => void
}

const SignBlock = (props: IProps) => {
	const { renderMode, block, signKey, signatures, onSignClick } = props
	const signedSignatures = ContentBlockHelper.getSignedSignatures(signatures)
	const unsignedUserSignature = ContentBlockHelper.getUnsignedUserSignature(signatures, signKey)
	const signableUnsignedSignature = unsignedUserSignature && onSignClick

	return (
		<Container>
			<Title>
				{block.title}
			</Title>

			<Description>
				{block.description}
			</Description>

			<Signatures>
				{signedSignatures.map(signature => {
					return (
						<Signature
							key={signature.id}
							signature={signature}
						/>
					)
				})}

				{((renderMode === BlockRenderMode.EDITOR || signableUnsignedSignature) && signedSignatures.length !== signatures.length) && <SignatureContainer key='sign-here'>
					<SignatureCanvas>
						<SignAction onClick={onSignClick}>
							<Icon icon='signature' />
							{block.action}
						</SignAction>
					</SignatureCanvas>
				</SignatureContainer>}

				{(renderMode === BlockRenderMode.RENDER && signedSignatures.length !== signatures.length) && <SignatureContainer key='render-sign-here'>
					<SignatureCanvas>
						<SignAction onClick={onSignClick}>
							<Icon icon='signature' />
							{block.action}
						</SignAction>
					</SignatureCanvas>
				</SignatureContainer>}
			</Signatures>
		</Container>
	)
}

export default SignBlock