import * as React from 'react'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import mustache from 'mustache'
import styled from 'styled-components'
import { IntroBlockTitle } from '../InputBlocks/IntroBlock'
import { Style } from '../../../styles'
import { ContentBlockVariables, IntroBlock as IntroBlockType } from '../../../types'

const Container = styled.div`
	display: flex;
	flex-direction: row;

	@media screen and (max-width: ${Style.breakpoints.CONTENTABLE_MOBILE}) {
		flex-direction: column;
	}
`

const IntroBlockContainer = styled.div`
	flex: 1;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
	padding: ${Style.spacing.x2};

	&:last-child {
		margin-left: ${Style.spacing.x2};

		@media screen and (max-width: ${Style.breakpoints.CONTENTABLE_MOBILE}) {
			margin-top: ${Style.spacing.x2};
			margin-left: 0;
		}
	}
`

type IProps = {
	block: IntroBlockType
	variables: ContentBlockVariables
}

const IntroBlock = (props: IProps) => {
	const { block, variables } = props
	const [toRenderError, setToRenderError] = React.useState<string | null>(null)
	const [fromRenderError, setFromRenderError] = React.useState<string | null>(null)

	let toContent = block.to
	let fromContent = block.from
	try {
		if (!toRenderError) toContent = mustache.render(block.to, variables)
	} catch (error) {
		setToRenderError(error.message)
		console.log(error.message)
	}

	try {
		if (!fromRenderError) fromContent = mustache.render(block.from, variables)
	} catch (error) {
		setFromRenderError(error.message)
		console.log(error.message)
	}

	return (
		<Container>
			<IntroBlockContainer className='text-editor'>
				<IntroBlockTitle>
					{variables && variables.contact && variables.contact.name}
				</IntroBlockTitle>
				<FroalaEditorView
					model={toContent}
				/>
			</IntroBlockContainer>

			<IntroBlockContainer className='text-editor'>
				<IntroBlockTitle>
					{variables && variables.workspace && variables.workspace.business_name}
				</IntroBlockTitle>
				<FroalaEditorView
					model={fromContent}
				/>
			</IntroBlockContainer>
		</Container>
	)
}

export default IntroBlock