import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { SubmitBlock as SubmitBlockType } from '../../../types'

const SubmitButton = styled.input`
	display: block;
	justify-content: center;
	align-items: center;
	width: fit-content !important;
	color: white !important;
	padding: ${Style.spacing.x1};
	background-color: ${Style.color.brandSuccess} !important;
	border: none !important;
	font-weight: bold !important;
	text-align: center !important;
	width: 100% !important;
	border-radius: 5px;
	cursor: pointer;
`

const Container = styled.div``

interface IProps {
	block: SubmitBlockType
}

const SubmitBlock = (props: IProps) => {
	const { block } = props
	return (
		<Container>
			<SubmitButton type='submit' value={block.text} />
		</Container>
	)
}

export default SubmitBlock