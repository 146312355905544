import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { useTranslation } from 'react-i18next'
import AddressInput from './Inputs/AddressInput'
import CountriesInput from './Inputs/CountriesInput'
import DateInput from './Inputs/DateInput'
import DropdownInput from './Inputs/DropdownInput'
import EmailInput from './Inputs/EmailInput'
import FileUploadInput from './Inputs/FileUploadInput'
import LinkInput from './Inputs/LinkInput'
import LongTextInput from './Inputs/LongTextInput'
import NumberInput from './Inputs/NumberInput'
import TextInput from './Inputs/TextInput'
import SliderInput from './Inputs/SliderInput'
import { AddressInputBlock, ContentBlockType, CountriesInputBlock, DateInputBlock, DropdownInputBlock, EmailInputBlock, FileUploadInputBlock, LinkInputBlock, LongTextInputBlock, NumberInputBlock, PhonenumberInputBlock, SliderInputBlock, TextInputBlock } from '../../../types'

const Container = styled.div`
	background: white;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

export const Title = styled.div`
	font-weight: 700;
	font-size: 16px;
	color: black;
`

const RequiredIndicator = styled.span`
	color: ${Style.color.brandDanger};
`

export const Description = styled.div``

const InputContainer = styled.div`
	margin-top: ${Style.spacing.x1};
`

type IProps = {
	block: AddressInputBlock | CountriesInputBlock | DateInputBlock | DropdownInputBlock | EmailInputBlock | FileUploadInputBlock | LinkInputBlock | LongTextInputBlock | NumberInputBlock | PhonenumberInputBlock | TextInputBlock | SliderInputBlock
}

const InputBlock = (props: IProps) => {
	const { t } = useTranslation()
	const { block } = props

	return (
		<Container>
			<div className='form-item'>
				<Title>
					{block.name}
					{block.required && <RequiredIndicator>*</RequiredIndicator>}
				</Title>

				{block?.description?.length > 0 && <Description>
					{block.description}
				</Description>}

				<InputContainer>
					{block.type === ContentBlockType.ADDRESS_INPUT && <AddressInput block={block} />}
					{block.type === ContentBlockType.COUNTRIES_INPUT && <CountriesInput block={block} />}
					{block.type === ContentBlockType.DATE_INPUT && <DateInput block={block} />}
					{block.type === ContentBlockType.DROPDOWN_INPUT && <DropdownInput block={block} />}
					{block.type === ContentBlockType.EMAIL_INPUT && <EmailInput block={block} />}
					{block.type === ContentBlockType.FILE_UPLOAD_INPUT && <FileUploadInput block={block} />}
					{block.type === ContentBlockType.LINK_INPUT && <LinkInput block={block} />}
					{block.type === ContentBlockType.LONG_TEXT_INPUT && <LongTextInput block={block} />}
					{block.type === ContentBlockType.NUMBER_INPUT && <NumberInput block={block} />}
					{block.type === ContentBlockType.PHONE_NUMBER_INPUT && <TextInput block={block} placeholder={t('InputBlock::00-1-202-588-6500')} />}
					{block.type === ContentBlockType.TEXT_INPUT && <TextInput block={block} />}
					{block.type === ContentBlockType.SLIDER_INPUT && <SliderInput block={block} />}
				</InputContainer>
			</div>
		</Container>
	)
}

export default InputBlock