import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CountriesInputBlock } from '../../../../types'

interface IProps {
	block: CountriesInputBlock
}

const CountriesInput = (props: IProps) => {
	const { t } = useTranslation()
	const { block } = props
	const { country_options } = block

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<div className='form-item'>
					<div className='select-wrapper'>
						<select name={block.name} required={block.required} placeholder={t('CountriesInput::Country')} defaultValue={country_options[0].value}>
							{country_options.map(countryOption => {
								return (
									<option key={countryOption.value} value={countryOption.value}>{countryOption.label}</option>
								)
							})}
						</select>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CountriesInput