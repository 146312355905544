import * as React from 'react'
import { PhonenumberInputBlock, TextInputBlock } from '../../../../types'

interface IProps {
	block: TextInputBlock | PhonenumberInputBlock
	placeholder?: string
}

const TextInput = (props: IProps) => {
	const { block, placeholder } = props

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<input
					name={block.name}
					type='text'
					placeholder={placeholder || block?.placeholder}
					required={block.required}
				/>
			</div>
		</div>
	)
}

export default TextInput