import * as React from 'react'
import { NumberInputBlock } from '../../../../types'

interface IProps {
	block: NumberInputBlock
}

const NumberInput = (props: IProps) => {
	const { block } = props

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<input
					name={block.name}
					type='number'
					required={block.required}
					min={block.minimum_limit}
					max={block.maximum_limit}
					placeholder={block.placeholder || ''}
				/>
			</div>
		</div>
	)
}

export default NumberInput 