import { Moment } from 'moment'
import * as React from 'react'
import { DateInputBlock } from '../../../../types'
import moment from '../../../../utilities/Moment'
import DateInput from '../../../Form/DateInput'

interface IProps {
	block: DateInputBlock
}

const DateInputComponent = (props: IProps) => {
	const { block } = props
	const [date, setDate] = React.useState<Moment | null>(null)

	const onDateChange = (value) => {
		const newDate = moment(value)

		if (newDate.isValid()) {
			setDate(newDate)
		}
	}

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<div className='form-item'>
					<DateInput
						name='date-input'
						timeFormat={false}
						initialValue={null}
						inputProps={{
							placeholder: 'YYYY-MM-DD',
							required: block.required,
						}}
						onChange={onDateChange}
						closeOnSelect
					/>
					<input type='hidden' name={block.name} value={date ? date.format('DD/MM/YYYY') : null} required={block.required} />
				</div>
			</div>
		</div>
	)
}

export default DateInputComponent