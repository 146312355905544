import * as React from 'react'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import mustache from 'mustache'
import styled from 'styled-components'
import { ContentBlockVariables, TextBlock as TextBlockType } from '../../../types'

type IProps = {
	block: TextBlockType
	variables: ContentBlockVariables
}

const Container = styled.div``

const TextBlock = (props: IProps) => {
	const { block, variables } = props
	const [renderError, setRenderError] = React.useState<string | null>(null)

	if (block.content === '') return null

	let content = block.content

	try {
		if (!renderError) {
			content = mustache.render(block.content, variables)
		}
	} catch (error) {
		setRenderError(error.message)
	}

	return (
		<Container className='text-editor'>
			<FroalaEditorView
				model={content}
			/>
		</Container>
	)
}

export default TextBlock