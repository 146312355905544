import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AddressInputBlock } from '../../../../types'

interface IProps {
	block: AddressInputBlock
}

const AddressInput = (props: IProps) => {
	const { block } = props
	const { t } = useTranslation()
	const [address, setAddress] = React.useState('')
	const [city, setCity] = React.useState('')
	const [country, setCountry] = React.useState('')
	const [postal, setPostal] = React.useState('')

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<div className='form-item'>
					<input
						type='text'
						placeholder={t('AddressInput::Address')}
						value={address}
						onChange={(e) => setAddress(e.target.value)}
						required={block.required}
					/>
				</div>
			</div>

			<div className='grid-cell with-12col'>
				<div className='form-item'>
					<input
						type='text'
						placeholder={t('AddressInput::City')}
						value={city}
						onChange={(e) => setCity(e.target.value)}
						required={block.required}
					/>
				</div>
			</div>

			<div className='grid-cell with-6col'>
				<div className='form-item'>
					<input
						type='text'
						placeholder={t('AddressInput::Country')}
						value={country}
						onChange={(e) => setCountry(e.target.value)}
						required={block.required}
					/>
				</div>
			</div>
			<div className='grid-cell with-6col'>
				<div className='form-item'>
					<input
						type='text'
						placeholder={t('AddressInput::Postal code')}
						value={postal}
						onChange={(e) => setPostal(e.target.value)}
						required={block.required}
					/>
				</div>
			</div>
			<input
				type='text'
				name={block.name}
				value={[address, city, country, postal].filter(v => v !== '').join(';')}
				style={{ display: 'none' }}
			/>
		</div>
	)
}

export default AddressInput