import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EmailInputBlock } from '../../../../types'

interface IProps {
	block: EmailInputBlock
}

const EmailInput = (props: IProps) => {
	const { block } = props
	const { t } = useTranslation()

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<input
					type='email'
					name={block.name}
					required={block.required}
					placeholder={block.placeholder || 'jane@gmail.com'}
				/>
			</div>
		</div>
	)
}

export default EmailInput