import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { QuestionAndAnswerBlock as QuestionAndAnswerBlockType, QuestionAndAnswerType } from '../../../types'
import { EditBlockProps } from '../Block'
import Icon from '../../Icons/Icon'
import PowerSelect from '../../Form/PowerSelect'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

const Container = styled.div`
	background: white;
	border-bottom-left-radius: ${Style.variables.baseBorderRadius};
	border-bottom-right-radius: ${Style.variables.baseBorderRadius};
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
`

const QuestionIconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 33px;
	height: 33px;
	min-width: 33px;
	min-height: 33px;
	border-radius: 50%;
	background: rgb(0, 140, 172);
	color: white;

	i {
		font-size: 20px;
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: none;
	}
`

const QuestionContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: ${Style.spacing.x1_5};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-left: 0;
	}
`

const QuestionTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
	width: 100%;
	margin-bottom: ${Style.spacing.x1_5};
`

type IProps = {} & EditBlockProps<QuestionAndAnswerBlockType>

const QuestionAndAnswerBlock = (props: IProps) => {
	const { t } = useTranslation()
	const { block } = props
	const [answer, setAnswer] = React.useState<string | string[]>(block.answer)
	const options = block?.options?.map(option => ({ label: option, value: option }))
	const selectedOptions = options?.filter(option => Array.isArray(answer) ? answer.includes(option.value) : option.value === answer)

	React.useEffect(() => {
		if (block.answer !== answer) {
			setAnswer(block.answer)
		}
	}, [block.answer])

	const debouncedAnswerChange = useDebouncedCallback(
		(answer) => onAnswerChange(answer), 500
	);

	const onAnswerChange = (text) => {
		setAnswer(text)
		props.onChange({ ...block, answer: text })
	}

	const onAnswerOptionChange = (options) => {
		if (block.allow_multiple_selection) {
			const newValues: string[] = options ? options.map(o => o.value) : null

			setAnswer(newValues)
			props.onChange({ ...block, answer: newValues })
		} else {
			const newValue = options ? options.value : null

			setAnswer(newValue)
			props.onChange({ ...block, answer: newValue })
		}
	}


	return (
		<Container id={block.id}>
			<Wrapper>
				<QuestionIconContainer>
					<Icon icon='question-circle' />
				</QuestionIconContainer>
				<QuestionContent>
					<QuestionTitle>
						{block.title}
					</QuestionTitle>
					{block.answer_type === QuestionAndAnswerType.OPEN && <textarea
						defaultValue={(answer as unknown) as string}
						onChange={e => debouncedAnswerChange(e.currentTarget.value)}
						placeholder={t('QuestionAndAnswerBlock::Type your answer here...')}
					/>}
					{/* @ts-ignore */}
					{block.answer_type === QuestionAndAnswerType.LIST && <PowerSelect
						value={selectedOptions}
						options={options}
						onChange={onAnswerOptionChange}
						isClearable
						placeholder={t('QuestionAndAnswerBlock::Select your answer...')}
						// @ts-ignore
						isMulti={Boolean(block.allow_multiple_selection)}
					/>}
				</QuestionContent>
			</Wrapper>
		</Container>
	)
}

export default QuestionAndAnswerBlock