import * as React from 'react'
import { LongTextInputBlock } from '../../../../types'
import { useTranslation } from 'react-i18next'

interface IProps {
	block: LongTextInputBlock
}

const LongTextInput = (props: IProps) => {
	const { block } = props
	const { t } = useTranslation()

	return (
		<div className='grid'>
			<div className='grid-cell with-12col'>
				<textarea
					name={block.name}
					required={block.required}
					placeholder={block.placeholder || t('LongTextInput::Type your answer here...')}
				/>
			</div>
		</div>
	)
}

export default LongTextInput 