// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'

// Import froala css
import './css'

// Custom plugin
import './plugin/variables'

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import Editor from 'react-froala-wysiwyg';
import store from '../../store';
import { FROALA_KEY } from '../../Constants';

export const DEFAULT_EDITOR_CONFIG = {
	key: FROALA_KEY,
	editorClass: 'text-editor',
	zIndex: 'initial',
	attribution: false,
	quickInsertEnabled: false,
	toolbarButtons: {
		moreText: {
			// List of buttons used in the  group.
			buttons: ['variable', 'quickInsert', 'bold', 'italic', 'underline', 'strikeThrough', 'paragraphFormat', 'fontFamily', 'fontSize', 'clearFormatting', 'color', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', 'insertLink', 'insertImage', 'insertTable', 'insertHR'],
			buttonsVisible: 21,
			// Alignment of the group in the toolbar.
			align: 'left',
		},
		'moreActions': {
			buttons: ['undo', 'redo', 'fullscreen'],
			align: 'right'
		}
	},
	// Variable plugin
	variableOptions: [],

	// Set request headers (authentication)
	requestHeaders: {
		'Authorization': store.getState().authentication.accessToken
	},

	// Set the image upload parameter.
	imageUploadParam: 'file',

	// Set the image upload URL.
	imageUploadURL: '/api/uploads',

	// Additional upload params.
	imageUploadParams: {},

	// Set request type.
	imageUploadMethod: 'POST',

	// Set max image size to 5MB.
	imageMaxSize: 5 * 1024 * 1024,

	// Allow to upload PNG and JPG.
	imageAllowedTypes: ['jpeg', 'jpg', 'png', 'svg'],

	events: {
		'image.beforeUpload': (images) => {
			// Return false if you want to stop the image upload.
		},
		'image.uploaded': (response) => {
			// Image was uploaded to the server.
		},
		'image.inserted': ($img, response) => {
			// Image was inserted in the editor.
		},
		'image.replaced': ($img, response) => {
			// Image was replaced in the editor.
		},
		'image.error': (error, response) => {
			// Bad link.
			if (error.code == 1) { }

			// No link in upload response.
			else if (error.code == 2) { }

			// Error during image upload.
			else if (error.code == 3) { }

			// Parsing response failed.
			else if (error.code == 4) { }

			// Image too text-large.
			else if (error.code == 5) { }

			// Invalid image type.
			else if (error.code == 6) { }

			// Image can be uploaded only to same domain in IE 8 and IE 9.
			else if (error.code == 7) { }

			// Response contains the original server response to the request if available.
		}
	}
}

export const NOTES_EDITOR_CONFIG = {
	...DEFAULT_EDITOR_CONFIG,
	toolbarButtons: ['paragraphFormat', 'bold', 'italic', 'clearFormatting', 'formatOL', 'formatUL', 'insertLink', 'insertImage', 'insertTable'],
}

export const EMAIL_EDITOR_CONFIG = {
	...DEFAULT_EDITOR_CONFIG,
	toolbarButtons: ['variable', 'paragraphFormat', 'bold', 'italic', 'clearFormatting', 'formatOL', 'formatUL', 'insertLink'],
}

export const MINIMAL_EDITOR_CONFIG = {
	...DEFAULT_EDITOR_CONFIG,
	toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatOL', 'formatUL', 'insertLink', 'insertTable', 'insertHR', 'variable'],
}

export const VARIABLE_EDITOR_CONFIG = {
	...DEFAULT_EDITOR_CONFIG,
	toolbarButtons: ['variable'],
}

export const EMAIL_COMPOSER_EDITOR_CONFIG = {
	...DEFAULT_EDITOR_CONFIG,
	editorClass: 'email-composer',
	toolbarButtons: ['bold', 'italic', 'underline', 'textColor', 'formatOL', 'formatUL', 'insertLink', 'insertImage', 'clearFormatting'],
	toolbarBottom: true
}

export const QUESTION_ANSWER_EDITOR_CONFIG = {
	...DEFAULT_EDITOR_CONFIG,
	toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'clearFormatting'],
	toolbarBottom: true,
}

export const BLOCK_EDITOR_CONFIG = {
	...DEFAULT_EDITOR_CONFIG,
	toolbarInline: true,
	toolbarButtons: {
		moreText: {
			buttons: ['paragraphFormat', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'clearFormatting'],
			buttonsVisible: 4,
			// Alignment of the group in the toolbar.
			align: 'left',
		},
		moreParagraph: {
			buttons: ['formatOL', 'formatUL', 'quote', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'outdent', 'indent'],
			align: 'left',
			buttonsVisible: 2,
		},
		moreRich: {
			buttons: ['insertLink', 'insertImage', 'insertTable'],
			buttonsVisible: 2,
			align: 'right'
		}
	},
	paragraphFormatSelection: true,
	fontFamilySelection: true
}

export const LEDGER_ITEM_EDITOR_CONFIG = {
	...DEFAULT_EDITOR_CONFIG,
	editorClass: 'text-editor ledger-item-editor',
	toolbarInline: true,
	toolbarButtons: {
		moreText: {
			buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatOL', 'formatUL', 'clearFormatting'],
			buttonsVisible: 10,
			// Alignment of the group in the toolbar.
			align: 'right',
		},
	},
	paragraphFormatSelection: true,
	heightMin: 64,
}

export const CALENDAR_EVENT_EDITOR_CONFIG = {
	...DEFAULT_EDITOR_CONFIG,
	toolbarButtons: {
		moreText: {
			buttons: ['bold', 'italic', 'underline', 'formatOL', 'formatUL', 'insertLink', 'clearFormatting'],
			buttonsVisible: 10,
		},
	},
}

export default Editor