import * as React from 'react'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import ContentBlockHelper from '../../../helpers/ContentBlockHelper'
import { Style } from '../../../styles'
import { ItemBlock, ItemsBlock as ItemsBlockType, ItemsSelectionRequirementType, ItemsView, NumberFormat } from '../../../types'
import NumberFormatter from '../../../utilities/NumberFormatter'
import { BlockRenderMode } from '../BlocksRenderer'
import SelectionButton from '../SelectionButton'

const Container = styled.div`
	background: white;
	break-inside: avoid;
`

const Title = styled.div`
	font-weight: 700;
	font-size: 18.75px;
	margin-bottom: ${Style.spacing.x1};
`

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;

	&::-webkit-scrollbar {
    background: white;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }
`

const TableContainer = styled.div`
	overflow: hidden;
	border-radius: 8px;

	@media screen and (max-width: ${Style.breakpoints.CONTENTABLE_MOBILE}) {
		overflow-y: auto;
		overflow-x: visible;
	}
`

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;

	thead {
		tr {
			border-bottom: 1px solid ${Style.color.border};
		}

		th {
			position: sticky;
			top: 0;
			background: #f7f7fa;
			z-index: 1;
		}
	}

	tbody {
		tr {
			&:not(:last-child) {
				border-bottom: 1px solid ${Style.color.border};
			}
		}
	}

	th {
		font-size: 14px;
		padding: ${Style.spacing.x1};
		font-weight: normal;
		text-align: right;
	}

	td {
		text-align: right;
		padding: ${Style.spacing.x1};
		vertical-align: top;
		white-space: nowrap;

		&.bold {
			font-weight: 700;
		}
	}
`

const ItemContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`

const ItemCover = styled.div`
	width: 50px;
	height: 50px;
	min-width: 50px;
	min-height: 50px;
	border-radius: 5px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: ${Style.spacing.x1_5};
`
const ItemContent = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	width: 100%;
`
const ItemTitle = styled.div`
	font-weight: 700;
	font-size: 16px;
	white-space: normal;
`
const ItemDescription = styled.div`
	white-space: normal;
`

const Cards = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -${Style.spacing.x1};
	margin-right: -${Style.spacing.x1};

	@media screen and (max-width: ${Style.breakpoints.CONTENTABLE_MOBILE}) {
		flex-direction: column;
		flex-wrap: none;
	}
`

const Card = styled.div<{ itemsCount: number }>`
	display: flex;
	flex-direction: column;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
	margin: ${Style.spacing.x1};
	flex: 1 1 calc((100% - 48px) / ${props => Math.min(props.itemsCount, 3)});
	max-width: calc((100% - 48px) / ${props => Math.min(props.itemsCount, 3)});

	${props => props.itemsCount === 1 && css`
		width: 100%;
		max-width: calc(100% / 3);
	`}

	@media screen and (max-width: ${Style.breakpoints.CONTENTABLE_MOBILE}) {
		max-width: initial;
		margin-left: 0;
		margin-right: 0;
	}
`

const CardInfo = styled.div`
	padding: ${Style.spacing.x1};
`

const CardCover = styled.div`
	width: 100%;
	min-height: 100px;
	border-radius: 5px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`

const CardSummary = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: flex-end;
	padding: ${Style.spacing.x1};
	border-top: 2px dotted ${Style.color.border};
`

const CardSummaryItem = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: ${Style.spacing.x0_5} 0;
`

const CardSummaryItemLabel = styled.div`
	text-align: right;
	margin-right: ${Style.spacing.x1};
`

const CardSummaryItemValue = styled.div`
	text-align: right;

	&.bold {
		font-weight: bold;
	}
`

type IProps = {
	renderMode?: BlockRenderMode
	currency: string
	numberFormat: NumberFormat
	block: ItemsBlockType
	onItemSelectionChange?: (itemsBlock: ItemsBlockType, itemIndex: number) => void
}

const ItemsBlock = (props: IProps) => {
	const { renderMode, currency, numberFormat, block } = props
	const { t } = useTranslation()
	const items = block.items
	const blockSummary = ContentBlockHelper.getItemsBlockSummary(block)
	const selectionEnabled = block.contact_selection_enabled

	const onItemSelectionChange = (item: ItemBlock, index: number) => {
		if (props.onItemSelectionChange) {
			props.onItemSelectionChange(block, index)
		}
	}

	const renderSelectionButton = (item: ItemBlock, index: number) => {
		const { selected } = item

		return (
			<SelectionButton
				selected={selected}
				icon={selected ? 'check' : 'plus'}
				text={selected ? t('ItemsBlocks::Selected') : (block.selection_requirement_type === ItemsSelectionRequirementType.OPTIONAL ? t('ItemsBlock::Add') : t('ItemsBlocks::Select'))}
				onClick={() => onItemSelectionChange(item, index)}
			/>
		)
	}

	return (
		<Container>
			<Title>
				{block.title}
			</Title>

			{block.view === ItemsView.TABLE && <ContentContainer>
				<TableContainer>
					<Table>
						<thead>
							<tr>
								<th style={{ textAlign: 'left' }}>{t('ItemsBlocks::Description')}</th>
								<th>{t('ItemsBlocks::Quantity')}</th>
								<th>{t('ItemsBlocks::Amount')}</th>
								<th>{t('ItemsBlocks::VAT')}</th>
								<th>{t('ItemsBlocks::Total')}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{items.map((item, index) => {
								const { total, tax } = ContentBlockHelper.getItemSummary(item)
								return (
									<tr key={index}>
										<td style={{ maxWidth: 250 }}>
											<ItemContentWrapper>
												{item.cover && <ItemCover
													style={{ backgroundImage: `url(${item.cover})` }}
												/>}
												<ItemContent>
													<ItemTitle>{item.title}</ItemTitle>
													<ItemDescription className='text-editor'>
														<FroalaEditorView
															model={item.description}
														/>
													</ItemDescription>
												</ItemContent>
											</ItemContentWrapper>
										</td>
										<td>
											{item.quantity}
										</td>
										<td>
											{NumberFormatter.formatCurrency(currency, numberFormat, item.amount)}
										</td>

										<td>
											{NumberFormatter.formatCurrency(currency, numberFormat, tax || 0)}
										</td>

										<td className='bold'>
											{NumberFormatter.formatCurrency(currency, numberFormat, total)}
										</td>

										<td>
											{selectionEnabled && renderSelectionButton(item, index)}
										</td>
									</tr>
								)
							})}
						</tbody>
					</Table>
				</TableContainer>

			</ContentContainer>}
			{block.view === ItemsView.CARD && <Cards>
				{items.map((item, index) => {
					const { total, tax } = ContentBlockHelper.getItemSummary(item)

					return (
						<Card key={index} itemsCount={items.length}>
							<CardInfo>
								{item.cover && <CardCover
									style={{ backgroundImage: `url(${item.cover})` }}
								/>}
								<ItemContent>
									<ItemTitle>{item.title}</ItemTitle>
									<ItemDescription className='text-editor'>
										<FroalaEditorView
											model={item.description}
										/>
									</ItemDescription>
								</ItemContent>
							</CardInfo>
							<CardSummary>
								<CardSummaryItem>
									<CardSummaryItemLabel>
										{t('ItemsBlocks::Quantity:')}
									</CardSummaryItemLabel>
									<CardSummaryItemValue>
										{item.quantity}
									</CardSummaryItemValue>
								</CardSummaryItem>
								<CardSummaryItem>
									<CardSummaryItemLabel>
										{t('ItemsBlocks::Amount:')}
									</CardSummaryItemLabel>
									<CardSummaryItemValue>
										{NumberFormatter.formatCurrency(currency, numberFormat, item.amount)}
									</CardSummaryItemValue>
								</CardSummaryItem>
								{tax > 0 && <CardSummaryItem>
									<CardSummaryItemLabel>
										{t('ItemsBlocks::Tax:')}
									</CardSummaryItemLabel>
									<CardSummaryItemValue>
										{NumberFormatter.formatCurrency(currency, numberFormat, tax)}
									</CardSummaryItemValue>
								</CardSummaryItem>}
								<CardSummaryItem>
									<CardSummaryItemLabel>
										{t('ItemsBlocks::Total:')}
									</CardSummaryItemLabel>
									<CardSummaryItemValue className='bold'>
										{NumberFormatter.formatCurrency(currency, numberFormat, total)}
									</CardSummaryItemValue>
								</CardSummaryItem>
								{selectionEnabled && <>
									{renderSelectionButton(item, index)}
								</>}
							</CardSummary>
						</Card>
					)
				})}
			</Cards>}
		</Container>
	)
}

export default ItemsBlock