import * as React from 'react'

interface IProps {
	className?: string
	children: React.ReactNode
}

const BlockContent = (props: IProps) => {
	return (
		<div className={`block-content ${props.className}`}>
			{props.children}
		</div>
	)
}

export default BlockContent