import * as React from 'react'
import mustache from 'mustache'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { WithTranslation, withTranslation } from "react-i18next"
import styled from 'styled-components'
import EditorHelper from '../../../helpers/EditorHelper'
import { Style } from '../../../styles'
import Editor, { MINIMAL_EDITOR_CONFIG } from '../../Editor/Editor'
import { EditBlockProps } from '../Block'
import { ContentBlockVariables, IntroBlock } from '../../../types'


const Container = styled.div`
	background: white;
	padding: 30px 20px;
	display: flex;
	flex-direction: row;
	border-radius: ${Style.variables.baseBorderRadius};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

export const IntroBlockTitle = styled.div`
	font-weight: 700;
	font-size: 18.75px;
	min-height: 30px;
	margin-bottom: ${Style.spacing.x0_5};
`

const IntroBlockContainer = styled.div`
	flex: 1;

	&:last-child {
		margin-left: ${Style.spacing.x2};

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			margin-left: 0;
			margin-top: ${Style.spacing.x2};
		}
	}
`

type IProps = {
	variables: ContentBlockVariables
} & EditBlockProps<IntroBlock> & WithTranslation

interface IState {
	editorMode: 'view' | 'edit'
	toModel: object | string
	fromModel: object | string
}

class IntroBlockComponent extends React.Component<IProps, IState> {
	private container = React.createRef<HTMLDivElement>()
	private toEditor = React.createRef<Editor>()
	private fromEditor = React.createRef<Editor>()

	constructor(props: IProps) {
		super(props)

		this.state = {
			editorMode: 'view',
			toModel: props.block.to,
			fromModel: props.block.from,
		}

		this.onContainerClick = this.onContainerClick.bind(this)
		this.onOutsideContainerClick = this.onOutsideContainerClick.bind(this)
		this.onToChange = this.onToChange.bind(this)
		this.debouncedToModelSubmit = this.debouncedToModelSubmit.bind(this)
		this.onFromChange = this.onFromChange.bind(this)
		this.debouncedFromModelSubmit = this.debouncedFromModelSubmit.bind(this)
	}

	componentDidMount(): void {
		document.addEventListener('mousedown', this.onOutsideContainerClick);
	}

	componentWillUnmount(): void {
		document.removeEventListener('mousedown', this.onOutsideContainerClick);
	}

	onContainerClick() {
		this.setState({ editorMode: 'edit' })
	}

	onOutsideContainerClick(e) {
		if (this.state.editorMode === 'edit') {
			if (this.container && !this.container.current.contains(e.target)) {
				this.setState({ editorMode: 'view' })
			}
		}
	}

	onToChange(toModel: object) {
		this.setState({ toModel: toModel })

		this.debouncedToModelSubmit()
	}

	debouncedToModelSubmit() {
		const { block } = this.props

		if (this.toEditor.current) {
			const content = this.toEditor.current.getEditor().html.get()
			this.props.onChange({ ...block, to: content })
		}
	}

	onFromChange(fromModel: object) {
		this.setState({ fromModel: fromModel })

		this.debouncedFromModelSubmit()
	}

	debouncedFromModelSubmit() {
		const { block } = this.props

		if (this.fromEditor.current) {
			const content = this.fromEditor.current.getEditor().html.get()
			this.props.onChange({ ...block, from: content })
		}
	}

	render() {
		const { variables, t, block } = this.props
		const { editorMode, toModel, fromModel } = this.state

		const editorConfig = {
			...MINIMAL_EDITOR_CONFIG,
			variableOptions: EditorHelper.getVariableOptions(variables),
		}

		let toContent = block.to
		let fromContent = block.from
		try {
			toContent = mustache.render(block.to, variables)
		} catch (error) {
			console.log(error.message)
		}

		try {
			fromContent = mustache.render(block.from, variables)
		} catch (error) {
			console.log(error.message)
		}

		return (
			<Container ref={this.container} onClick={this.onContainerClick}>
				<IntroBlockContainer className='text-editor'>
					<IntroBlockTitle>
						{variables && variables.contact && variables.contact.name}
					</IntroBlockTitle>
					{editorMode === 'view' && <FroalaEditorView model={toContent} />}

					{editorMode === 'edit' &&
						<Editor
							ref={this.toEditor}
							model={toModel}
							config={editorConfig}
							onModelChange={this.onToChange}
						/>
					}
				</IntroBlockContainer>

				<IntroBlockContainer className='text-editor'>
					<IntroBlockTitle>
						{variables && variables.workspace && variables.workspace.business_name}
					</IntroBlockTitle>
					{editorMode === 'view' && <FroalaEditorView model={fromContent} />}

					{editorMode === 'edit' &&
						<Editor
							ref={this.fromEditor}
							model={fromModel}
							config={editorConfig}
							onModelChange={this.onFromChange}
						/>
					}
				</IntroBlockContainer>
			</Container>
		)
	}
}

export default withTranslation()(IntroBlockComponent)