import * as React from 'react'
import styled from 'styled-components'
import { PDFBlock as PDFBlockType } from '../../../types'
import DocumentPreview from '../../LedgerItem/DocumentPreview'
import DocumentWrapper from '../../LedgerItem/DocumentWrapper'

const Container = styled.div`
	width: 100%;
	break-inside: avoid;
`


type IProps = {
	block: PDFBlockType
}

const PDFBlock = (props: IProps) => {
	const { block } = props

	if (block?.image_urls?.length === 0) return null

	return (
		<Container>
			{block.image_urls.map((url, index) => (<img src={url} key={index} />))}
		</Container>
	)
}

export default PDFBlock